<template>
  <b-container fluid>
      <b-row  class="text-dark mt-4">
       <b-col lg="12" md="12" sm="12">
          <iq-card style="padding:15px;">
            <b-overlay :show="loading">
                <template>
                    <b-row>
                        <b-col md="12 text-right mb-2">
                            <b-button @click="pdfExport()" class="btn-success btn-sm">
                                <i class="ri-printer-line"></i> {{  $t('globalTrans.print') }}
                            </b-button>
                        </b-col>
                        <b-col class="details-border">
                            <b-row>
                            <b-col lg="9" sm="12" xs="12">
                            <b-row style="font-weight:600">
                                <b-col lg="2" sm="12">
                                <p>{{ $t('pump_install.application_id') }}</p>
                                </b-col>
                                <b-col lg="1" sm="12">
                                <p>:</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                <p>{{ pumpOperatorApp.application_id }}</p>
                                </b-col>
                                <b-col lg="2" sm="12">
                                <p>{{ $t('globalTrans.date') }} </p>
                                </b-col>
                                <b-col lg="1" sm="12">
                                <p>:</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                <p>{{ pumpOperatorApp.created_at | dateFormat }}</p>
                                </b-col>
                                <b-col lg="2" sm="12">
                                <p>{{ $t('pump_install.organization') }}</p>
                                </b-col>
                                <b-col lg="1" sm="12">
                                <p>:</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                <p>{{ ($i18n.locale == 'bn') ? pumpOperatorApp.org_name_bn : pumpOperatorApp.org_name }}</p>
                                </b-col>
                                <b-col lg="2" sm="12">
                                <p>{{ $t('globalTrans.address') }}</p>
                                </b-col>
                                <b-col lg="1" sm="12">
                                <p>:</p>
                                </b-col>
                                <b-col lg="9" sm="12">
                                <p>{{ getAddress(pumpOperatorApp) }}</p>
                                </b-col>
                            </b-row>
                            </b-col>
                        <b-col lg="3" sm="12" xs="12">
                        <img :src="baseUrl + 'download-attachment?file=uploads/pump-operator-application/applicant-photo/original/' + pumpOperatorApp.applicant_photo" class="height-150 width-150 img-fluid" style="border:2px solid;margin-bottom:20px;" :alt="pumpOperatorApp.name"/>
                        </b-col>
                        <b-col lg="12" sm="12" style="margin-top:20px; margin-bottom: 20px;">
                        <p>{{ $t('pump_install.dear_sir') }},</p>
                        <p>{{ $t('pump_install.i_am_writing_for_pump_operator') }} {{ $t('externalUserIrrigation.pump_id') }} ({{ pumpOperatorApp.pump_info_pump_id }}), {{ $t('externalUserIrrigation.address') }}: {{ getPumpAddress(pumpOperatorApp) }} "</p>
                        </b-col>
                        <b-col lg="12" sm="12" style="margin-bottom: 40px;">
                        <p>{{ $t('pump_install.i_would_appreciate') }}</p>
                        </b-col>
                        </b-row>
                        <b-row style="font-weight:600">
                            <b-col lg="12" sm="12">
                            <p>{{ $t('pump_install.sincerely') }} : </p>
                            </b-col>
                            <b-col lg="2" sm="12">
                            <p>{{ $t('globalTrans.name')}}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                            <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                            <p>{{ ($i18n.locale == 'bn') ? pumpOperatorApp.name_bn : pumpOperatorApp.name }}</p>
                            </b-col>
                            <b-col lg="2" sm="12">
                            <p>{{ $t('pump_install.mobile') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                            <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                            <p>{{ pumpOperatorApp.far_mobile_no | mobileNumber}}</p>
                            </b-col>
                            <b-col lg="2" sm="12">
                            <p>{{ $t('pump_install.email') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                            <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                            <p>{{ pumpOperatorApp.email }}</p>
                            </b-col>
                            <b-col lg="12" sm="12" style="margin-top:20px">
                            <p class="text-center">[{{ $t('pump_install.this_is_computer_generated') }}]</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.pump_operator_new_application') + ' ' + $t('externalUserIrrigation.details') }}</h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" sm="12">
                                  <b-row>
                                    <b-col lg="3" sm="12">
                                        <p class="text-dark" style="font-weight:bold">{{ $t('org_pro.organization') }} :</p>
                                    </b-col>
                                    <b-col lg="9" sm="12">
                                        <p>{{ (this.$i18n.locale=='bn') ? pumpOperatorApp.org_name_bn : pumpOperatorApp.org_name }}</p>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col lg="3" sm="12">
                                        <p style="font-weight:bold" >{{ $t('pump_install.pump_id') }} :</p>
                                    </b-col>
                                    <b-col lg="9" sm="12">
                                        <p v-html="pumpOperatorApp.pump_info_pump_id"></p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col lg="12" sm="12">
                                        <div>
                                            <b-table-simple striped bordered small>
                                                <b-tr>
                                                <b-th>{{ $t('user.namel') }}</b-th>
                                                <b-td>{{ ($i18n.locale === 'bn')? pumpOperatorApp.name_bn : pumpOperatorApp.name }}</b-td>
                                                <b-th>{{ $t('externalUserIrrigation.date_of_birth') }}</b-th>
                                                <b-td>{{ pumpOperatorApp.date_of_birth | dateFormat }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                <b-th>{{ $t('externalUserIrrigation.gender') }}</b-th>
                                                <b-td>{{ getGender(pumpOperatorApp.gender) }}</b-td>
                                                <b-th></b-th>
                                                <b-td></b-td>
                                                </b-tr>
                                                <b-tr>
                                                <b-th>{{ $t('externalUserIrrigation.father_name') }}</b-th>
                                                <b-td>{{ ($i18n.locale === 'bn')? pumpOperatorApp.father_name_bn : pumpOperatorApp.father_name }}</b-td>
                                                <b-th>{{ $t('externalUserIrrigation.mother_name') }}</b-th>
                                                <b-td>{{ ($i18n.locale === 'bn')? pumpOperatorApp.mother_name_bn : pumpOperatorApp.mother_name }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                <b-th>{{ $t('org_pro_division.division') }}</b-th>
                                                <b-td>{{ ($i18n.locale ==='bn')? pumpOperatorApp.division_name_bn : pumpOperatorApp.division_name }}</b-td>
                                                <b-th>{{ $t('org_pro_district.district') }}</b-th>
                                                <b-td>{{ ($i18n.locale === 'bn')? pumpOperatorApp.district_name_bn : pumpOperatorApp.district_name }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                <b-th>{{ $t('org_pro_upazilla.upazilla') }}</b-th>
                                                <b-td>{{ ($i18n.locale === 'bn')? pumpOperatorApp.upazilla_name_bn : pumpOperatorApp.upazilla_name }}</b-td>
                                                <b-th>{{ $t('org_pro_union.union') }}</b-th>
                                                <b-td>{{ ($i18n.locale === 'bn')? pumpOperatorApp.union_name_bn : pumpOperatorApp.union_name }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                <b-th>{{ $t('externalUserIrrigation.village') }}</b-th>
                                                <b-td>{{ ($i18n.locale === 'bn')? pumpOperatorApp.far_village_bn : pumpOperatorApp.far_village }}</b-td>
                                                <b-th>{{ $t('externalUserIrrigation.educational_qualification') }}</b-th>
                                                <b-td>{{ pumpOperatorApp.qualification }}</b-td>
                                                </b-tr>
                                                <b-tr>
                                                <b-th>{{ $t('user.phone_no') }}</b-th>
                                                <b-td>{{ ($i18n.locale === 'bn') ? '০' + $n(pumpOperatorApp.far_mobile_no, { useGrouping: false }) : pumpOperatorApp.far_mobile_no }}</b-td>
                                                <b-th>{{ $t('externalUserIrrigation.nid') }}</b-th>
                                                <b-td colspan="3">{{ $n(pumpOperatorApp.nid, { useGrouping: false }) }}</b-td>
                                                </b-tr>
                                            </b-table-simple>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row v-if="attachments.length > 0">
                                    <b-col lg="12" sm="12" class="text-center mb-4">
                                        <h5 class='complain-title'>{{ $t('pump_install.attachment') }}</h5>
                                    </b-col>
                                </b-row>
                                <b-row v-if="attachments.length > 0">
                                    <b-col lg="12" sm="12">
                                        <div>
                                            <b-table-simple striped bordered small>
                                                <b-tr>
                                                <b-th>{{ $t('pump_install.document_title') }}</b-th>
                                                <b-th>{{ $t('pump_install.attachment') }}</b-th>
                                                </b-tr>
                                                <b-tr v-for="(attachment,index) in attachments" :key="index">
                                                <b-td>{{ ($i18n.locale == 'bn') ? attachment.document_title_bn : attachment.document_title }}</b-td>
                                                <b-td>
                                                    <a v-if="attachment.attachment !== null" :href="baseUrl + 'download-attachment?file=uploads/pump-operator-document/original/' + attachment.attachment" title="Attachment" class="mr-3"><i class="ri-download-cloud-fill"></i></a>
                                                </b-td>
                                                </b-tr>
                                            </b-table-simple>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row v-if="approvals.length > 0">
                        <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.send') + ' ' + $t('pump_install.notel') }}</h5>
                        </b-col>
                        <b-col lg="12" sm="12">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <div>
                                        <b-table-simple striped bordered small>
                                            <b-tr>
                                                <b-th>{{ $t('user.namel') }}</b-th>
                                                <b-th>{{ $t('pump_install.send_for') }}</b-th>
                                                <b-th>{{ $t('pump_install.notel') }}</b-th>
                                            </b-tr>
                                            <b-tr v-for="(approval,index) in approvals" :key="index">
                                                <b-td>{{ getUserName(approval.sender_id) }}</b-td>
                                                <b-td>{{ (approval.for === 1) ? $t('pump_install.check') : $t('pump_install.survey') }}</b-td>
                                                <b-td>{{ ($i18n == 'bn')? approval.note_bn : approval.note }}</b-td>
                                            </b-tr>
                                        </b-table-simple>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        </b-row>
                        <b-row style="margin-top:20px;" v-if="surveys.length > 0">
                            <b-col lg="12" sm="12" class="text-center mb-4">
                            <h5 class='complain-title'>{{ $t('pump_install.survey') + ' ' + $t('pump_install.notel') }}</h5>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <b-row>
                                    <b-col lg="12" sm="12">
                                        <div>
                                            <b-table-simple striped bordered small>
                                                <b-tr>
                                                    <b-th>{{ $t('user.namel') }}</b-th>
                                                    <b-th>{{ $t('pump_install.survey_date') }}</b-th>
                                                    <b-th>{{ $t('pump_install.notel') }}</b-th>
                                                </b-tr>
                                                <b-tr v-for="(survey,index) in surveys" :key="index">
                                                    <b-td>{{ getUserName(survey.user_id) }}</b-td>
                                                    <b-td>{{ survey.survey_date | dateFormat }}</b-td>
                                                    <b-td>{{ ($i18n == 'bn')? survey.note_bn : survey.note }}</b-td>
                                                </b-tr>
                                            </b-table-simple>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        </b-col>
                    </b-row>
                </template>
            </b-overlay>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptApplicationDetailsList } from '../../api/routes'
import Custom from '../../../../../store/custom'
import ExportPdf from './pump_operator_application_details_pdf'

export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    this.loadData()
  },
  data () {
    return {
      pumpOperatorApp: '',
      attachments: [],
      approvals: [],
      surveys: [],
      slOffset: 1,
      baseUrl: irriSchemeServiceBaseUrl,
      loading: false
    }
  },
  methods: {
    loadData () {
      this.loading = true
      RestApi.getData(irriSchemeServiceBaseUrl, pumpOptApplicationDetailsList + '/' + this.$props.id).then(response => {
        if (response.success) {
          this.pumpOperatorApp = this.formatList(response.data)
          this.attachments = response.attachments
        }
      this.loading = false
      })
    },
    formatList (data) {
      const orgList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
      const divisionList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList

      const orgPro = orgList.find(org => org.value === data.org_id)
      const division = divisionList.find(division => division.value === data.far_division_id)
      const district = districtList.find(district => district.value === data.far_district_id)
      const upazilla = upazilaList.find(upazilla => upazilla.value === data.far_upazilla_id)
      const union = unionList.find(union => union.value === data.far_union_id)
      return Object.assign({}, data,
        { org_name: orgPro.text_en, org_name_bn: orgPro.text_bn },
        { division_name: division !== undefined ? division.text_en : '', division_name_bn: division !== undefined ? division.text_bn : '' },
        { district_name: district !== undefined ? district.text_en : '', district_name_bn: district !== undefined ? district.text_bn : '' },
        { upazilla_name: upazilla !== undefined ? upazilla.text_en : '', upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : '' },
        { union_name: union !== undefined ? union.text_en : '', union_name_bn: union !== undefined ? union.text_bn : '' }
      )
    },
    getGender (genId) {
      const gender = Custom.genderListEx.find(item => item.value === genId)
      if (gender) {
        if (this.$i18n.locale === 'bn') {
          return gender.text_bn
        } else {
          return gender.text_en
        }
      }
    },
    getAddress (item) {
      const address = []
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.far_village_bn !== undefined ? item.far_village_bn : '', tmpUnion ? tmpUnion.text_bn : '')
      } else {
        address.push(item.far_village !== undefined ? item.far_village : '', tmpUnion ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    getPumpAddress (item) {
      const address = []
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mouza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion !== undefined ? tmpUnion.text_bn : '')
      } else {
        address.push(item.pump_mouza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion !== undefined ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    pdfExport () {
      const reportTitle = this.$t('admission_form.application_details')
      ExportPdf.exportPdfDetails(reportTitle, this,
        this.pumpOperatorApp
      )
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
    .details-border {
        border: 1px solid gray;
        padding: 8px;
        border-radius: 5px;
        box-shadow: 1px 2px 3px gray;
    }
</style>
