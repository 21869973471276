import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-external'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, purpose, vm) => {
    try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      {
        columns: reportHeadData.reportHeadColumn, style: 'main_head'
      },
      { text: reportHeadData.address, style: 'address', alignment: 'center' }
    ]
    /**
     * header end pdf
     */
      pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
      const allRows = [
        [
            { text: vm.$t('externalUserIrrigation.application_title'), style: 'td' },
            { text: vm.$t('externalUserIrrigation.pump_operator_application'), style: 'td' }
        ],
        [
            { text: vm.$t('externalUserIrrigation.application_id'), style: 'td' },
            { text: data.application_id, style: 'td' }
        ],
        [
            { text: vm.$t('farmerOperator.applicant_name'), style: 'td' },
            { text: (i18n.locale === 'bn') ? data.name_bn : data.name, style: 'td' }
        ],
        [
            { text: vm.$t('externalUserIrrigation.purpose'), style: 'td' },
            { text: purpose, style: 'td' }
        ],
        [
            { text: vm.$t('admission_form.amount'), style: 'td' },
            { text: vm.$n(data.amount), style: 'td' }
        ],
        [
            { text: vm.$t('externalUserIrrigation.payment_date'), style: 'td' },
            { text: dateFormat(data.payment_date), style: 'td' }
        ],
        [
            { text: vm.$t('externalUserIrrigation.transaction_id'), style: 'td' },
            { text: data.transaction_no, style: 'td' }
        ],
        [
            { text: '[ This is a computer generated document. No Signature is required ]', colSpan: 2, style: 'td', alignment: 'center' },
            {}
        ]
      ]
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: '*',
          body: allRows
        }
      })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 10]
          },
          main_head: {
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
        if (error) {}
      }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
