<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" md="12" sm="12">
                  <b-button variant="primary" @click="pdfExport" class="mr-2 mb-2 btn-sm float-right">
                    <i class="fa fa-print"></i> {{  $t('globalTrans.print') }}
                  </b-button>
                  <div id="moneyReceiptArea">
                    <b-table-simple border>
                      <slot v-if="Object.keys(payment).length !== 0">
                        <b-tr>
                            <b-th colspan="3" class="text-center">{{ $t('externalUserIrrigation.money_receipt_title') }}</b-th>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('externalUserIrrigation.application_title') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ $t('externalUserIrrigation.pump_operator_application') }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('externalUserIrrigation.application_id') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ payment.application_id }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('farmerOperator.applicant_name') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ ($i18n.locale === 'bn') ? payment.name_bn : payment.name }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('externalUserIrrigation.purpose') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ getPurpose(payment.payment_type_id) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('admission_form.amount') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ $n(payment.amount) }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('externalUserIrrigation.payment_date') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ payment.payment_date | dateFormat }}</b-td>
                        </b-tr>
                        <b-tr>
                            <b-td>{{ $t('externalUserIrrigation.transaction_id') }}</b-td>
                            <b-td>:</b-td>
                            <b-td>{{ payment.transaction_no }}</b-td>
                        </b-tr>
                        <b-tr>
                            <td colspan="3" class="text-center">[{{ $t('pump_install.this_is_computer_generated') }}]</td>
                        </b-tr>
                      </slot>
                      <slot v-else>
                        <b-tr>
                            <td colspan="3" class="text-center">Sorry, Data not found</td>
                        </b-tr>
                      </slot>
                    </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { getPumpOptMoneyReceipt } from '../../api/routes'
import MoneyReceipt from './money_receipt'

export default {
  props: ['appId', 'applicationType', 'paymentTypeTd'],
  mounted () {
    core.index()
  },
  created () {
    this.getPaymentDetails(this.appId, this.applicationType, this.paymentTypeTd)
  },
  data () {
    return {
      loading: false,
      payment: {}
    }
  },
  methods: {
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Money Receipt' : 'অর্থ প্রদানের রসিদ'
      const purpose = this.getPurpose(this.payment.payment_type_id)
      MoneyReceipt.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', this.payment.org_id, reportTitle, this.payment, purpose, this)
    },
    async getPaymentDetails (appId, appType, paymentTypeTd) {
      this.loading = true
      const param = {
        application_id: appId,
        application_type: appType,
        payment_type_id: paymentTypeTd
      }
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, getPumpOptMoneyReceipt, param)
      this.loading = false
      if (result.success) {
        this.payment = result.data
      }
    },
    getPurpose (paymentTypeId) {
      if (paymentTypeId === 1) {
        return this.$t('externalUserIrrigation.application_fee')
      } else if (paymentTypeId === 2) {
        return this.$t('externalUserIrrigation.renew_fee')
      } else {
        return this.$t('externalUserIrrigation.security_money')
      }
    },
    print () {
      var printArea = document.getElementById('moneyReceiptArea').innerHTML
      var a = window.open('', '', 'height=500, width=500')
      a.document.write('<html><body>')
      a.document.write(printArea)
      a.document.write('</body></html>')
      a.document.close()
      a.print()
    }
  }
}
</script>
