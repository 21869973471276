<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col lg="11" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                    <b-form  @submit.prevent="handleSubmit(register)" >
                      <ValidationProvider name="Amount" vid='amount' rules="required">
                        <b-form-group
                          class="row"
                          label-cols-sm="12"
                          :label="$t('externalUserIrrigation.payment')"
                          label-for="amount"
                          slot-scope="{ valid, errors }"
                        >
                          <b-form-input
                            id="amount"
                            v-model="payment.amount"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                      <b-row>
                          <b-col>
                            <b-button class="btn-block" type="submit" variant="primary">{{ $t('globalTrans.pay') }}</b-button>
                          </b-col>
                          <b-col>
                            <b-button @click="payment.is_bypass = 1" class="btn-block" type="submit" variant="warning">Bypass {{ saveBtnName}}</b-button>
                          </b-col>
                      </b-row>
                    </b-form>
                  </ValidationObserver>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpOptApplicationFee, getPumpOperatorPayment } from '../../api/routes'

export default {
  props: ['application'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAmount()
  },
  data () {
    return {
      saveBtnName: this.$t('globalTrans.save'),
      payment: {
        org_id: this.application.org_id,
        master_payment_id: '',
        far_application_id: this.application.id,
        amount: '',
        is_bypass: 0
      }
    }
  },
  computed: {
    loading () {
      return this.$store.state.ExternalUserIrrigation.static.loading
    }
  },
  methods: {
    async register () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
      let result = null
      result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOptApplicationFee, this.payment)
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: true })
      if (result.success) {
        if (result.success === 2) {
          // push notification send
          // const notification = result.notification
          // this.$socket.emit('send-notification', notification)

          this.$toast.success({
            title: 'Success',
            message: result.message
          })
          this.$router.go()
        } else {
          window.location = result.url
        }
        this.$bvModal.hide('modal-2')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: result.message
        })
      }
    },
    async getAmount () {
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
      const param = { org_id: this.application.org_id, payment_type_id: 1 }
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, getPumpOperatorPayment, param)
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false })
      if (result.success) {
        this.payment.master_payment_id = result.data.id
        this.payment.amount = result.data.amount
      }
    }
  }
}
</script>
