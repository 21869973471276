import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

function getBase64ImageFromURL (url) {
  return new Promise((resolve, reject) => {
    var img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')

    img.onload = () => {
      var canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      var dataURL = canvas.toDataURL('image/png')

      resolve(dataURL)
    }

    img.onerror = error => {
      reject(error)
    }

    img.src = url
  })
}

const exportPdfDetails = async (reportTitle, thisObject, data) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      { text: reportTitle, style: 'header2', alignment: 'center' }
    ]
    // pdfContent.push([
    //   { image: data.sch_man_photo !== null ? await getBase64ImageFromURL(irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/scheme-application/scheme-manager-photo/original/' + data.sch_man_photo) : '', width: 100, height: 100, style: 'search', alignment: 'center' }
    // ])
    const applicationTopRowsHead = []
    if (data.applicant_photo !== null) {
      applicationTopRowsHead.push([
          { text: thisObject.$t('pump_install.application_id'), alignment: 'left', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: data.application_id, alignment: 'left', style: 'search' },
          { image: await getBase64ImageFromURL(irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/pump-operator-application/applicant-photo/original/' + data.applicant_photo), width: 100, height: 100, style: 'search', alignment: 'right', rowSpan: 5 }
      ])
    } else {
      applicationTopRowsHead.push([
          { text: thisObject.$t('pump_install.application_id'), alignment: 'left', style: 'search' },
          { text: ':', style: 'search', alignment: 'center' },
          { text: data.application_id, alignment: 'left', style: 'search' },
          { text: 'Profile Photo Not Found', style: 'search', alignment: 'right', rowSpan: 5 }
      ])
    }

  applicationTopRowsHead.push([
    { text: thisObject.$t('globalTrans.date'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: dateFormat(data.created_at), alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.organization'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('globalTrans.address'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: i18n.locale === 'en' ? data.far_village + ', ' + data.union_name + ', ' + data.upazilla_name + ', ' + data.district_name + ', ' + data.division_name : data.far_village_bn + ', ' + data.union_name_bn + ', ' + data.upazilla_name_bn + ', ' + data.district_name_bn + ', ' + data.division_name_bn, alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.dear_sir') + ',', alignment: 'left', style: 'search', colSpan: 3 },
    {},
    {},
    {}
  ])

  applicationTopRowsHead.push([
    { text: i18n.locale === 'en' ? thisObject.$t('pump_install.i_am_writing_for_pump_operator') + '(' + data.pump_info_pump_id + '), ' + 'address: ' + data.pump_mouza_no + ', ' + data.pump_jl_no + ', ' + data.pump_plot_no + ', ' + data.union_name + ', ' + data.upazilla_name + ', ' + data.district_name + ', ' + data.division_name : thisObject.$t('pump_install.i_am_writing_for_pump_operator') + '(' + data.pump_info_pump_id + '), ' + 'address: ' + data.pump_mouza_no + ', ' + thisObject.$n(data.pump_jl_no, { useGrouping: false }) + ', ' + thisObject.$n(data.pump_plot_no, { useGrouping: false }) + ', ' + data.union_name_bn + ', ' + data.upazilla_name_bn + ', ' + data.district_name_bn + ', ' + data.division_name_bn, alignment: 'justify', style: 'search', colSpan: 4 },
    {},
    {},
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.i_would_appreciate'), alignment: 'justify', style: 'search', colSpan: 4 },
    {},
    {},
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.sincerely') + ',', alignment: 'left', style: 'search', colSpan: 4 },
    {},
    {},
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('globalTrans.name'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: i18n.locale === 'en' ? data.name : data.name_bn, alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.mobile'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: thisObject.$n('0') + thisObject.$n(data.far_mobile_no, { useGrouping: false }), alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.email'), alignment: 'left', style: 'search' },
    { text: ':', style: 'search', alignment: 'center' },
    { text: data.email, alignment: 'left', style: 'search' },
    {}
  ])

  applicationTopRowsHead.push([
    { text: thisObject.$t('pump_install.this_is_computer_generated'), alignment: 'center', style: 'search', colSpan: 4 },
    {},
    {},
    {}
  ])

    pdfContent.push({
      table: {
        headerRows: 0,
        widths: ['15%', '2%', '50%', '30%'],
        body: applicationTopRowsHead
      },
      layout: {
        hLineWidth: function (i, node) {
          return 0
        },
        vLineWidth: function (i, node) {
          return 0
        }
      }
    })

    // Application Details
    pdfContent.push([
      { text: thisObject.$t('globalTrans.details'), style: 'marginTopStyle', alignment: 'center', decoration: 'underline' }
    ])

    const allRowsHead = [
        [
        { text: thisObject.$t('org_pro.organization'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('pump_install.pump_id'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: data.pump_id, alignment: 'left', style: 'search' }
      ]
    ]

    allRowsHead.push([
        { text: thisObject.$t('user.namel'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.name : data.name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('externalUserIrrigation.date_of_birth'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: dateFormat(data.date_of_birth), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.father_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.father_name : data.father_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.mother_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.mother_name : data.mother_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.nid'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.nid, { useGrouping: false }), alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.mobile'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n('0') + thisObject.$n(data.far_mobile_no, { useGrouping: false }), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.division'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.division_name : data.division_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.district'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.district_name : data.district_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.upazila'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.upazilla_name : data.upazilla_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('pump_install.union'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.union_name : data.union_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pump_install.village'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.far_village : data.far_village_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('externalUserIrrigation.educational_qualification'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.qualification : data.qualification, alignment: 'left', style: 'search' }
    ])

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })

      const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'Portrait',
      watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 12 : 10,
          margin: [3, 3, 3, 3],
          bold: true
        },
        td: {
          fontSize: (i18n === 'bn') ? 12 : 10,
          margin: [3, 3, 3, 3]
        },
        search: {
          fontSize: (i18n === 'bn') ? 12 : 10,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [0, 10, 0, 7]
        },
        fertilizerSHeader: {
            fontSize: 10,
            margin: [40, 0, 0, 0]
        },
        header: {
          fontSize: 12,
          margin: [0, 0, 0, 4]
        },
        header2: {
          fontSize: i18n.locale === 'bn' ? 14 : 12,
          margin: [0, 10, 0, 10]
        },
        header3: {
          fontSize: i18n.locale === 'bn' ? 13 : 11,
          margin: [0, 10, 0, 5]
        },
        marginTopStyle: {
          fontSize: i18n.locale === 'bn' ? 14 : 12,
          margin: [0, 50, 0, 5]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        address: {
          fontSize: 9,
          margin: [0, -10, 0, 0]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
