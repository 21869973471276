<template>
  <b-container fluid>
      <b-row>
        <b-button @click="pdfExport()" class="ml-4 btn-success water-test-pdf-button">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
        <b-col lg="12" sm="12"  class="text-dark">
            <iq-card>
                <b-overlay :show="loading">
                    <template>
                        <b-row>
                            <b-col lg="12" sm="12" class="text-center mb-4">
                                <h5 class='complain-title'>{{ $t('externalUserIrrigation.appointment_letter') }}</h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <p>{{ $t('externalUserIrrigation.to') }} : <span style="font-weight:600">{{ this.$i18n.locale=='bn' ? appLetter.name_bn : appLetter.name }}</span></p>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <p>{{ $t('externalUserIrrigation.on_behalf_of') }} {{ this.$i18n.locale=='bn' ? appLetter.org_name_bn : appLetter.org_name }}, {{ $t('externalUserIrrigation.we_here_by_inform') }}</p>
                            </b-col>
                            <b-col lg="2" sm="12">
                                <p>{{ $t('externalUserIrrigation.position') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                                <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                                <span style="font-weight:600">{{ $t('externalUserIrrigation.pump_operator') }}</span>
                            </b-col>
                            <b-col lg="2" sm="12">
                                <p>{{ $t('externalUserIrrigation.operator_id') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                                <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                                <span style="font-weight:600">{{ appLetter.far_mobile_no | mobileNumber}}</span>
                            </b-col>
                            <b-col lg="2" sm="12">
                                <p>{{ $t('pump_install.pump_id') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                                <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                                <span style="font-weight:600">{{ appLetter.pump_information.pump_id }}</span>
                            </b-col>
                            <b-col lg="2" sm="12">
                                <p>{{ $t('pump_install.pump_address') }}</p>
                            </b-col>
                            <b-col lg="1" sm="12">
                                <p>:</p>
                            </b-col>
                            <b-col lg="9" sm="12">
                                <span style="font-weight:600">{{ getPumpAddress(appLetter) }}</span>
                            </b-col>
                            <b-col lg="12" sm="12">
                                <p>{{ $t('externalUserIrrigation.we_look_forward_to') }}</p>
                            </b-col>
                            <b-col lg="12" sm="12" style="margin-top:20px">
                                <p class="text-center">[{{ $t('pump_install.this_is_computer_generated') }}}]</p>
                            </b-col>
                        </b-row>
                    </template>
                </b-overlay>
            </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { irriSchemeServiceBaseUrl } from '@/config/api_config'
import ExportPdf from './appointment_letter_pdf'

export default {
  name: 'FormLayout',
  props: ['application'],
  data () {
    return {
      appLetter: '',
      pumpOperatorApp: '',
      attachments: [],
      slOffset: 1,
      baseUrl: irriSchemeServiceBaseUrl,
      loading: false
    }
  },
  created () {
    const orgList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
    const application = this.$props.application
    const orgPro = orgList.find(org => org.value === application.org_id)
    this.appLetter = Object.assign({}, application,
        { org_name: orgPro.text_en, org_name_bn: orgPro.text_bn }
    )
  },
  methods: {
    getPumpAddress (item) {
      const address = []
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.pump_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.pump_mouza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion !== undefined ? tmpUnion.text_bn : '')
      } else {
        address.push(item.pump_mouza_no, item.pump_jl_no, item.pump_plot_no, tmpUnion !== undefined ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.pump_upazilla_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila !== undefined ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila !== undefined ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.pump_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict !== undefined ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict !== undefined ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.pump_division_id)
       if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision !== undefined ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision !== undefined ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    pdfExport () {
      const reportTitle = this.$t('externalUserIrrigation.appointment_letter')
      ExportPdf.exportPdfDetails(reportTitle, this,
        this.appLetter
      )
    }
  }
}
</script>
