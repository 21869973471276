<template>
  <b-container fluid>
    <b-row v-if="loadMsg">
        <b-col>
            <b-overlay :show='true'>
                <h4 class="text-center">{{ $t('globalTrans.loading') }} ...</h4>
            </b-overlay>
        </b-col>
    </b-row>
    <div v-else>
        <b-row v-if="overlay" variant="default" show>
            <div class="box-message col-md-12 mt-5 text-danger">
                <div class="arrow">
                    <div class="outer"></div>
                    <div class="inner"></div>
                </div>
                <div class="message-body">
                    <p class="pb-2 pt-2 h5"><i class="ri-alert-fill mr-2"></i>
                      <!-- {{ $t('externalUserIrrigation.dashboard_message') }} -->
                      {{ $t('externalPanel.profile_complete_msg_part1')}}
                      <router-link :to="{ path: '/irrigation/my-profile' }">
                        {{ $t('externalPanel.profile_complete_msg_part2')}}
                      </router-link>
                      {{ $t('externalPanel.profile_complete_msg_part3')}}
                    </p>
                </div>
            </div>
        </b-row>
        <div v-else>
            <b-row>
                <b-col md="12">
                    <b-modal id="modal-7" size="lg" :title="$t('pump_install.application_details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <p>
                        <PumpOptApplicationDetails :id="testId" :key="id"/>
                    </p>
                    </b-modal>
                    <b-modal id="modal-2" size="md" :title="$t('externalUserIrrigation.application') + ' ' + $t('externalUserIrrigation.payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <p>
                        <ApplicationFee :application="application"/>
                    </p>
                    </b-modal>
                    <b-modal id="modal-3" size="md" :title="$t('externalUserIrrigation.renew') + ' ' + $t('externalUserIrrigation.payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <p>
                        <RenewFee :application="application"/>
                    </p>
                    </b-modal>
                    <b-modal id="modal-1" size="md" :title="$t('externalUserIrrigation.security') + ' ' + $t('externalUserIrrigation.payment')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <p>
                        <SecurityFee :application="application"/>
                    </p>
                    </b-modal>
                    <b-modal id="modal-9" size="lg" :title="$t('externalUserIrrigation.appointment_letter')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                    <p>
                        <AppointmentLetter :application="application"/>
                    </p>
                    </b-modal>
                </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('externalUserIrrigation.pump_operator_application') }}</h4>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col>
                        <div class="stepper-wrapper">
                            <div :class="(listData[0].status >= 1) ? `stepper-item completed`: 'stepper-item'">
                                <div class="step-counter"><i v-if="listData[0].status >= 1" class="ri-check-fill"></i> <span v-else>{{ $n(1) }}</span></div>
                                <div class="step-name">{{ getStatus(1) }}</div>
                            </div>
                            <div :class="(listData[0].status >= 1) ? `stepper-item completed`: 'stepper-item'">
                                <div class="step-counter"><i v-if="listData[0].payment_status === 1 && listData[0].status === 2" class="ri-check-fill"></i> <span v-else>{{ $n(2) }}</span></div>
                                <div class="step-name">{{ getPaymentStatus(listData[0].payment_status)  }}</div>
                            </div>
                            <div :class="(listData[0].status >= 2) ? `stepper-item completed`: 'stepper-item'">
                                <div class="step-counter"><i v-if="listData[0].status > 2" class="ri-check-fill"></i> <span v-else>{{ $n(3) }}</span></div>
                                <div class="step-name">{{ getStatus(2) }}</div>
                            </div>
                            <div :class="(listData[0].status >= 3) ? `stepper-item completed`: 'stepper-item'">
                                <div class="step-counter"><i v-if="listData[0].status > 3" class="ri-check-fill"></i> <span v-else>{{ $n(4) }}</span></div>
                                <div class="step-name">{{ getStatus(3) }}</div>
                            </div>
                            <div v-if="listData[0].status === 4" :class="(listData[0].status >= 4) ? `stepper-item completed`: 'stepper-item'">
                                <div class="step-counter"><i v-if="listData[0].status > 4" class="ri-check-fill"></i> <span v-else>{{ $n(5) }}</span></div>
                                <div class="step-name">{{ getStatus(4) }}</div>
                            </div>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col>
                        <b-table-simple bordered>
                          <tr>
                            <th>{{ $t('externalUserIrrigation.fee') }}</th>
                            <th>{{ $t('externalUserIrrigation.money_receipt') }}</th>
                            <th>{{ $t('globalTrans.action') }}</th>
                          </tr>
                          <tr>
                            <td class="fee">
                              <b-button v-if="listData[0].payment_status === 0" class="btn-outline-success ml-1 mt-1" v-b-modal.modal-2 variant=" iq-bg-success mr-1" size="sm" title="Application Payment" @click="applicationFeePayment(listData[0])">{{ $t('externalUserIrrigation.application_fee') }}</b-button>
                              <b-button v-if="listData[0].renew_payment_status === 0" class="btn-outline-success ml-1 mt-1" v-b-modal.modal-3 variant=" iq-bg-success mr-1" size="sm" title="Renew Payment" @click="renewFeePayment(listData[0])">{{ $t('externalUserIrrigation.renew_fee') }}</b-button>
                              <b-button v-if="listData[0].payment_status != 2 && listData[0].status === 3" class="btn-outline-success ml-1 mt-1" v-b-modal.modal-1 variant=" iq-bg-success mr-1" size="sm" title="Security Money" @click="fromFeePayment(listData[0])">{{ $t('externalUserIrrigation.security_money') }}</b-button>
                            </td>
                            <td class="money-receipt">
                              <b-button v-if="listData[0].is_renew === 0 && listData[0].payment_status === 1"
                                class="btn-outline-success mt-1"
                                variant=" iq-bg-success ml-1"
                                size="sm"
                                @click="showMoneyReceipt(listData[0], 2, 1)"
                              >
                                {{ $t('externalUserIrrigation.application_fee') }}
                              </b-button>
                              <b-button v-if="listData[0].renew_payment_status === 1"
                                class="btn-outline-success mt-1"
                                variant=" iq-bg-success ml-1"
                                size="sm"
                                @click="showMoneyReceipt(listData[0], 2, 2)"
                              >
                                {{ $t('externalUserIrrigation.renew_fee') }}
                              </b-button>
                              <b-button v-if="listData[0].payment_status === 2"
                                class="btn-outline-success mt-1"
                                variant=" iq-bg-success ml-1"
                                size="sm"
                                @click="showMoneyReceipt(listData[0], 2, 3)"
                              >
                                {{ $t('externalUserIrrigation.security_money') }}
                              </b-button>
                            </td>
                            <td class="action">
                              <router-link v-if="listData[0].payment_status === 0 || listData[0].status === 4" class="btn btn-sm text-success" title="Edit Application" style="border:1px solid green" size="sm" :to="'/irrigation/pump-opt-application/' + listData[0].id"><i class="ri-ball-pen-fill m-0"></i></router-link>
                              <b-button v-if="listData[0].payment_status == 2" class="btn-outline-success ml-1" v-b-modal.modal-9 variant=" iq-bg-success mr-1" size="sm" title="Appointment Letter" @click="appointmentLetter(listData[0])">{{ $t('externalUserIrrigation.appointment_letter') }}</b-button>
                              <router-link v-if="listData[0].status === 3" class="btn btn-sm text-success mt-1" title="Application Renew" style="border:1px solid green" size="sm" :to='irrigation/pump-opt-renew/+listData[0].id'>{{ $t('externalUserIrrigation.renew') }}</router-link>
                            </td>
                          </tr>
                        </b-table-simple>
                      </b-col>
                    </b-row>
                    <PumpOptApplicationDetails :id="listData[0].id"/>
                    <b-modal id="modal-money-receipt" size="md" :title="$t('externalUserIrrigation.money_receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
                      <MoneyReceipt :appId="currentAppId" :applicationType="applicationType" :paymentTypeTd="paymentTypeTd" />
                    </b-modal>
                  </template>
                </iq-card>
              </b-col>
            </b-row>
        </div>
    </div>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { pumpOptApplicationSingleList, irrigationPayment, farmerInfo } from '../../api/routes'
import PumpOptApplicationDetails from './PumpOptApplicationDetails'
import SecurityFee from './SecurityFee'
import ApplicationFee from './ApplicationFee'
import AppointmentLetter from './AppointmentLetter'
import RenewFee from './RenewFee'
import MoneyReceipt from './MoneyReceipt'

export default {
  name: 'PumpOptApplicationList',
  components: {
    PumpOptApplicationDetails,
    SecurityFee,
    ApplicationFee,
    AppointmentLetter,
    RenewFee,
    MoneyReceipt
  },
  data () {
    return {
      loadMsg: false,
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: this.$store.state.ExternalUserIrrigation.commonObj.perPage,
        slOffset: 1
      },
      search: {},
      testId: 0,
      rows: [],
      ifApply: false,
      irriUrl: irriSchemeServiceBaseUrl,
      id: '',
      overlay: true,
      application: null,
      currentAppId: 0,
      applicationType: 0,
      paymentTypeTd: 0
    }
  },
  computed: {
    formTitle () {
       return (this.testId === 0) ? this.$t('org_pro.office') + ' ' + this.$t('globalTrans.entry') : this.$t('org_pro.office') + ' ' + this.$t('globalTrans.modify')
    },
    loading () {
      return this.$store.state.ExternalUserIrrigation.commonObj.loading
    },
    listReload () {
      return this.$store.state.ExternalUserIrrigation.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    listData () {
      return this.$store.state.ExternalUserIrrigation.pumpOptApplicationLists
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('pump_install.name'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.pump_address'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.payment_status'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.application_status'), class: 'text-left' },
          { label: this.$t('externalUserIrrigation.type'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'address' },
          { key: 'payment_status' },
          { key: 'status' },
          { key: 'is_renew' },
          { key: 'action' }
        ]
      } else {
        keys = [
         { key: 'index' },
          { key: 'name' },
          { key: 'address' },
          { key: 'payment_status' },
          { key: 'status' },
          { key: 'is_renew' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
    this.checkUser().then((res) => {
        if (!res.loggedFarmerId) {
          this.overlay = true
        } else {
          this.loadData()
        }
    })
    this.paymentStatusUpdate()
  },
  mounted () {
    core.index()
  },
  methods: {
    getAddress (item) {
      const address = []
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        address.push(item.far_village_bn !== undefined ? item.far_village_bn : '', tmpUnion ? tmpUnion.text_bn : '')
      } else {
        address.push(item.far_village !== undefined ? item.far_village : '', tmpUnion ? tmpUnion.text_en : '')
      }
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpUpazila ? tmpUpazila.text_bn : '')
      } else {
        address.push(tmpUpazila ? tmpUpazila.text_en : '')
      }
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDistrict ? tmpDistrict.text_bn : '')
      } else {
        address.push(tmpDistrict ? tmpDistrict.text_en : '')
      }
      const divisionList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        address.push(tmpDivision ? tmpDivision.text_bn : '')
      } else {
        address.push(tmpDivision ? tmpDivision.text_en : '')
      }
      return address.join(', ')
    },
    resetId () {
      this.testId = 0
    },
    default () {
      return {
        id: this.rows.length
      }
    },
    paginationData (data) {
      this.pagination.currentPage = data.current_page
      this.pagination.totalRows = data.total
      this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
    },
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.testId = item.id
    },
    fromFeePayment (item) {
      this.application = item
    },
    appointmentLetter (item) {
      this.application = item
    },
    applicationFeePayment (item) {
      this.application = item
    },
    renewFeePayment (item) {
      this.application = item
    },
    showMoneyReceipt (item, appType, paymentTypeTd) {
      this.currentAppId = item.id
      this.applicationType = appType
      this.paymentTypeTd = paymentTypeTd
      this.$bvModal.show('modal-money-receipt')
    },
    loadData () {
      this.loadMsg = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      RestApi.getData(irriSchemeServiceBaseUrl, pumpOptApplicationSingleList, params).then(response => {
        this.loadMsg = false
        if (response.success) {
          if (response.data.data.length < 1) {
            this.$router.push('/irrigation/pump-opt-application')
          }
          this.$store.dispatch('ExternalUserIrrigation/getPumpOptApplicationList', this.getRelationalData(response.data.data))
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const orgList = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList
      const divisionList = this.$store.state.ExternalUserIrrigation.commonObj.divisionList
      const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList
      const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList
      const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList
      return data.map(item => {
        const orgPro = orgList.find(org => org.value === item.org_id)
        const division = divisionList.find(division => division.value === item.far_division_id)
        const district = districtList.find(district => district.value === item.far_district_id)
        const upazilla = upazilaList.find(upazilla => upazilla.value === item.far_upazilla_id)
        const union = unionList.find(union => union.value === item.far_union_id)
        return Object.assign({}, item,
          { org_name: orgPro.text_en, org_name_bn: orgPro.text_bn },
          { division_name: division !== undefined ? division.text_en : '', division_name_bn: division !== undefined ? division.text_bn : '' },
          { district_name: district !== undefined ? district.text_en : '', district_name_bn: district !== undefined ? district.text_bn : '' },
          { upazilla_name: upazilla !== undefined ? upazilla.text_en : '', upazilla_name_bn: upazilla !== undefined ? upazilla.text_bn : '' },
          { union_name: union !== undefined ? union.text_en : '', union_name_bn: union !== undefined ? union.text_bn : '' }
        )
      })
    },
    async checkUser () {
        this.loadMsg = true
        return await RestApi.getData(irriSchemeServiceBaseUrl, farmerInfo).then(response => {
            this.loadMsg = false
            if (response.success) {
                this.overlay = false
                this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
                return { loggedFarmerId: response.data.id }
            }
        })
    },
    getPaymentStatus (payStatus) {
      if (payStatus === 0) {
        return this.$t('externalUserIrrigation.unpaid')
      } else {
        return this.$t('externalUserIrrigation.paid')
      }
    },
    getStatus (status) {
      if (status === 1) {
        return this.$t('pump_install.pending')
      } else if (status === 2) {
        return this.$t('pump_install.processing')
      } else if (status === 3) {
        return this.$t('pump_install.approve')
      } else if (status === 4) {
        return this.$t('pump_install.reject')
      } else {
        return this.$t('pump_install.pending')
      }
    },
    async paymentStatusUpdate () {
      if (this.$route.params.status && this.$route.query.transId) {
        const status = this.$route.params.status
        const tranId = this.$route.query.transId
        await RestApi.getData(irriSchemeServiceBaseUrl, irrigationPayment + status, { transId: tranId })
        .then(response => {
          if (response.success) {
            this.$toast.success({
              title: 'Success',
              message: response.message,
              color: '#D6E09B'
            })
          } else {
            this.$toast.error({
              title: 'Error',
              message: response.message
            })
          }
        })
        this.$router.push('/irrigation/pump-operator-application')
      }
    }
  }
}
</script>
<style scoped>
    .stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    }
    .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @media (max-width: 768px) {
        font-size: 12px;
    }
    }

    .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
    }

    .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
    }

    .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: white;
    }

    .stepper-item.active {
    font-weight: bold;
    }

    .stepper-item.completed .step-counter {
    background-color: #4bb543;
    color:white;
    }

    .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #4bb543;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
    }

    .stepper-item:first-child::before {
    content: none;
    }
    .stepper-item:last-child::after {
    content: none;
    }
    .step-name {
        color: black;
        font-weight: bold;
        font-size: 11px;
    }
</style>
